import React from 'react';
import { Grid, Box, Button } from '@mui/joy';
import { Link } from 'react-router-dom';

const Item = ({ children, to }: { children: React.ReactNode; to: string }) => (
  <Button
    component={Link}
    to={to}
    variant="solid"
    color="primary"
    sx={{
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: 'md',
      boxShadow: 'md',
      fontSize: '1em',
      textAlign: 'center',
      '&:hover': {
        boxShadow: 'lg',
      },
      textDecoration: 'none', // Ensures the text decoration is removed
      color: 'white', // Inherit text color from Button
    }}
  >
    {children}
  </Button>
);

const Home = () => (
  <Box sx={{ height: '100vh', overflow: 'hidden' }}>
    <Grid container spacing={2} sx={{ pt: 2, px: 2, height: '100%' }}>
      <Grid xs={8} sx={{ height: '33.33%' }}>
        <Item to="/robot">Wanna control the Robot?</Item>
      </Grid>
      <Grid xs={4} sx={{ height: '33.33%' }}>
        <Item to="/game">Check out our online game</Item>
      </Grid>
      <Grid xs={4} sx={{ height: '33.33%' }}>
        <Item to="/fun-fact">Fun fact of the Day</Item>
      </Grid>
      <Grid xs={8} sx={{ height: '33.33%' }}>
        <Item to="/favorite">What is your favorite...</Item>
      </Grid>
      <Grid xs={12} sx={{ height: '33.33%' }}>
        <Item to="/chat">Chat with us</Item>
      </Grid>
    </Grid>
  </Box>
);

export default Home;
