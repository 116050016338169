import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './components/Home';
import About from './components/About';
import Game from './components/Game';
import Chat from './components/Chat';
import './App.css';
import '@fontsource/inter';
import { CssVarsProvider } from "@mui/joy";
import { theme } from "./theme";

const App = () => (
  <CssVarsProvider theme={theme}>
    <Router>
      <Routes>
        <Route path="/" element={<Home/>}/>
        <Route path="/about" element={<About/>}/>
        <Route path="/game" element={<Game/>}/>
        <Route path="/chat" element={<Chat/>}/>
      </Routes>
    </Router>
  </CssVarsProvider>
);

export default App;
