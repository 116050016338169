import { extendTheme } from '@mui/joy/styles';

export const theme = extendTheme({
  colorSchemes: {
    light: {
      palette: {
        primary: {
          100: '#d8cfe0', // Lightest shade
          200: '#bfa9d0', // Light shade
          300: '#a67fb7', // Medium light shade
          400: '#8d5c9e', // Medium shade
          500: '#3F306E', // Main color
          600: '#3a2b62', // Dark shade
          700: '#33255a', // Darker shade
          800: '#2b1f4f', // Even darker shade
          900: '#1d143e', // Darkest shade
        },
      },
    },
  },
});
